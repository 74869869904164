import { createTheme } from "@uiw/codemirror-themes";
import CodeMirror, {
  BasicSetupOptions,
  Extension,
  ReactCodeMirrorProps,
} from "@uiw/react-codemirror";
import { tags as t } from "@lezer/highlight";
import { EditorView } from "@codemirror/view";
import { langs } from "@uiw/codemirror-extensions-langs";
import "./CDataCodeMirror.scss";

interface ICDataCodeMirror
  extends Omit<ReactCodeMirrorProps, "value" | "extensions"> {
  ["data-testid"]?: string;
  /** The contents of the CodeMirror. */
  sqlText: string;
  /** Custom CodeMirror extensions to apply to the component. */
  customExtensions?: Extension[];
  /** If true, the default extensions will be disabled. This can be useful if, for example, you need custom language handling for sql. */
  disableDefaultExtensions?: boolean;
}

export const CDataCodeMirror = (props: ICDataCodeMirror) => {
  const {
    ["data-testid"]: dataTestId,
    sqlText,
    customExtensions = [],
    disableDefaultExtensions,
    basicSetup,
    editable,
    theme,
    ...codeMirrorProps
  } = props;

  const cdataCodeMirrorTheme = createTheme({
    theme: "light",
    settings: {
      background: "#ffffff",
      foreground: "#000000",
      selection: "#E5EAEF",
      lineHighlight: "#ffffff",
      gutterBackground: "#F7F7F7",
      gutterForeground: "#999999",
    },
    styles: [
      { tag: t.variableName, color: "#770088" },
      { tag: t.keyword, color: "#770088" },
      { tag: t.number, color: "#116644" },
      { tag: t.bool, color: "#008855" },
      { tag: t.null, color: "#221199" },
    ],
  });

  const defaultBasicSetup = {
    dropCursor: true,
    foldGutter: false,
    syntaxHighlighting: true,
    highlightActiveLineGutter: false,
    highlightSelectionMatches: false,
    bracketMatching: false,
  };
  const customBasicSetup = basicSetup as BasicSetupOptions;

  const defaultExtensions = disableDefaultExtensions
    ? []
    : [langs.sql(), EditorView.lineWrapping];

  return (
    <div className="cdata-code-mirror">
      <CodeMirror
        data-testid={dataTestId}
        value={sqlText}
        extensions={[...defaultExtensions, ...customExtensions]}
        theme={theme ?? cdataCodeMirrorTheme}
        editable={editable ?? false}
        basicSetup={{
          ...defaultBasicSetup,
          ...customBasicSetup,
        }}
        {...codeMirrorProps}
      />
    </div>
  );
};
